import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import {NbAuthService} from '../@framework/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router) { }
  canActivate() {
    return this.authService.isAdmin().pipe(
      tap(res => {
        if (!res) {
          this.router.navigate(['/pages/miscellaneous/notAuthorized']);
        } else {
          return true;
        }
      }),
    );
  }
}
