import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbThemeModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';

@NgModule({
    declarations: [CookieConsentComponent ],
    imports: [NgcCookieConsentModule, CommonModule, NbThemeModule, TranslateModule],
    exports: [CookieConsentComponent],
})
export class CookiesModule {}
