import {Component, Input, OnInit} from '@angular/core';
import {NbSidebarService} from '@nebular/theme';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import { ProfileService } from '../../../services/profile.service';

@Component({
  selector: 'ngx-side-menu-avatar',
  templateUrl: './side-menu-avatar.component.html',
  styleUrls: ['./side-menu-avatar.component.scss'],
})
export class SideMenuAvatarComponent implements OnInit {
  @Input() userLabel: string = '';
  avatar: string;

  constructor(private sideMenuService: NbSidebarService,
              private router: Router,
              private profileService: ProfileService,
              private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.httpClient.get('/app/user/0?ui_version=v3')
      .pipe(map((res: any) => res.first_name + ' ' + res.last_name))
      .subscribe((data) => {
        this.userLabel = data;
      });


    this.httpClient
    .get('/app/my/avatar')
    .pipe(map((res: any) => res.base64_image))
    .subscribe(
      image => {
        if (image !== '') this.avatar = image;
      },
      err => {
        if (err.status === 404) return;
      },
      );

      this.profileService.avatar.subscribe((avatar) => {
        this.avatar = avatar.base64_image;
      });
  }

  goToProfile() {
    this.sideMenuService.collapse('menu-sidebar');
    this.router.navigateByUrl('/pages/user-profile');
  }

}
