import {Injectable} from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {tap} from 'rxjs/operators';
import {NbAuthService} from '../@framework/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    const startIndex = `${window.location}`.indexOf('/ui/') + 4;
    const redirectUrl = `${window.location}`.substr(startIndex);
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['/auth/login'], {queryParams: {'redirectURL': encodeURI(redirectUrl)}});
          }
        }),
      );
  }
}
