import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'ngx-language-view',
    template: `
        <div *ngIf="currentLanguage === 'en'">
            <button class="border-0 bg-transparent"  (click)="changeLanguageHandler('ar')">
                <span class="lang">عربي</span>
            </button>
        </div>
        <div *ngIf="currentLanguage === 'ar'">
            <button class="border-0 bg-transparent"  (click)="changeLanguageHandler('en')">
                <span class="lang">English</span>
            </button>
        </div>
    `,
    styleUrls: ['./language-view.component.scss'],
})
export class LanguageViewComponent {
    @Input() currentLanguage?: string = 'en';
    @Output() changeLanguage = new EventEmitter<string>();

    changeLanguageHandler(lang) {
        this.changeLanguage.emit(lang);
    }
}
