import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { TermsAndConditionsService } from './terms-and-conditions.service';

@Injectable({
  providedIn: 'root',
})
export class TaCEnabledGuard implements CanActivate {
  /**
   *
   */
  constructor(
    private tacService: TermsAndConditionsService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.tacService.tacEnabled();
  }
}
