import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

// export interface TaCAcceptance {
//     accepted_tac: boolean;
// }

export interface TaC {
  terms_and_conds: string;
}

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsService {
  protected TACURL = '/app/terms_and_conditions';
  constructor(
    private http: HttpClient,
  ) {}

  public userAcceptedTaC(): Observable<boolean> {
    return this.http.get<any>(`${this.TACURL}/1`).pipe(
      map((res) => Number.parseInt(res.accepted_tac, 10) === 1),
      catchError(() => of(true)),
    );
  }

  public getLocalizedTaC(): Observable<TaC> {
    return this.http.get<TaC>(`${this.TACURL}?localize=1`);
  }

  public sendUserTaCAcceptance(): Observable<any> {
    return this.http.post<any>(`${this.TACURL}`, { accepted_tac : 1 });
  }

  public tacEnabled(): Observable<boolean> {
    return this.http.get<any>(`/app/tac_enabled`).pipe(
      map(res => (res !== null && Number.parseInt(res.enable_tac, 10) === 1) ? true : false),
    );
  }
}
