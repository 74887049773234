import { tap, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TermsAndConditionsService } from './terms-and-conditions.service';
import { iif, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsGuard implements CanActivate {
  /**
   *
   */
  constructor(
    private router: Router,
    private tacService: TermsAndConditionsService,
  ) {}
  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.tacService.tacEnabled().pipe(
      mergeMap<boolean, Observable<boolean>>(res =>
        iif(
          /** Condition */
          () => res,

          /** True Result */
          this.tacService.userAcceptedTaC().pipe(
            tap((acc) => {
              if (!acc) {
                this.router.navigate(['/terms_and_conditions'], {queryParams: {'redirectURL': state.url}});
              } else {
                  return of(true);
              }
            }),
          ),

          /** False Result */
          of(true),
        ),
      ),
      catchError((err) => of(true)),
    );
  }
}
