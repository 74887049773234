import {NbAuthService} from './../@framework/auth/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from './../services/language.service';
import {TermsAndConditionsService, TaC} from './../services/terms-and-conditions.service';
import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {
  NbLayoutDirection,
  NbLayoutDirectionService,
  NbMenuItem,
  NbMenuService,
} from '@nebular/theme';

@Component({
  selector: 'ngx-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit  {
  public language: number = 1;
  public loading: boolean = true;
  public tacContent: string;
  public buttonEnabled: boolean = false;
  public tacAcc: boolean = false;
  redirect_url;

  public menuItems: NbMenuItem[];

  constructor(
    private tacService: TermsAndConditionsService,
    private languageService: LanguageService,
    private router: Router,
    private translateService: TranslateService,
    private directionService: NbLayoutDirectionService,
    private menuService: NbMenuService,
    private authService: NbAuthService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {
    this.menuService.onItemClick().subscribe((e) => {
      if (e.item && e.item.target && e.item.target === 'logout') {
        this.authService.logout('email');
      }
    });
    const params = this.route.snapshot.queryParams;
    if (params['redirectURL']) {
      this.redirect_url = params['redirectURL'];
    }
  }

  setDefaultLanguage(language: number) {
    if (language === 1) {
      this.translateService.setDefaultLang('en');
      this.directionService.setDirection(NbLayoutDirection.LTR);
    } else if (language === 2) {
      this.translateService.setDefaultLang('ar');
      this.directionService.setDirection(NbLayoutDirection.RTL);
    }
  }

  protected updateContent() {
    this.loading = true;
    this.tacService.getLocalizedTaC().subscribe((t: TaC) => {
      this.loading = false;
      this.tacContent = this.sanitizer.bypassSecurityTrustHtml(t.terms_and_conds) as string;
    });
  }

  protected updateTaCAcc() {
    this.tacService.userAcceptedTaC().subscribe((acc => {
      this.tacAcc = acc;
    }));
  }

  protected updateLanguage() {
    this.languageService.getUserSelectedLanguage().subscribe((l: number) => {
      this.language = l;
    });
  }

  protected updateMenuItems() {
    this.translateService.get('S33').subscribe((v) => {
      this.menuItems = [
        {
          title: v,
          data: v,
          icon: {
            icon: 'power-off',
            pack: 'fa-solid',
          },
          target: 'logout',
        },
      ];
    });
  }

  ngOnInit() {
    this.setDefaultLanguage(this.route.snapshot.data.useLanguage);
    this.updateMenuItems();
    this.updateTaCAcc();
    this.updateLanguage();
    this.updateContent();
  }

  submitTacAcceptance() {
    if (this.buttonEnabled)
      this.tacService.sendUserTaCAcceptance().subscribe(t => {
        if (this.redirect_url) {
          this.router.navigateByUrl(this.redirect_url);
        } else {
          this.router.navigate(['/']);
        }
      });
  }

  toggleButtonEnable() {
    this.buttonEnabled = !this.buttonEnabled;
  }
}
