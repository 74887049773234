import { Component, OnInit, Input } from '@angular/core';
import { ThemeSettingsService } from '../../../services/theme-settings.service';

@Component({
  selector: 'ngx-theme-mode',
  templateUrl: './theme-mode.component.html',
  styleUrls: ['./theme-mode.component.scss'],
})
export class ThemeModeComponent implements OnInit {

  themeModeChangeEnabled: boolean  = false;
  checked: boolean = false;
  @Input() showTitle: boolean = false;
  @Input() showHorizontalLine: boolean = false;
  constructor(
    private themeSettingsService: ThemeSettingsService,
  ) { }

  ngOnInit() {
    this.themeSettingsService
      .enableThemeMode$
      .subscribe((isEnabled) => {
        this.themeModeChangeEnabled = isEnabled;
      });

    this.themeSettingsService.themeMode$.subscribe((theme) => {
      this.checked = theme === 'dark';
    });
  }

  changeThemeMode(checked: boolean) {
    const mode = checked ? 'dark' : 'default';
    this.themeSettingsService.setUserThemeSettings({ theme_mode: mode }).subscribe();
  }

}
