import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-language',
  styleUrls: ['./language.component.scss'],
  templateUrl: './language.component.html',
})
export class LanguageComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  currentLanguage: any = null;
  languages: any[] = [];


  constructor( private http: HttpClient, private route: Router) {
  }

  ngOnInit() {
    this.http.get('/app/language').pipe(first()).subscribe((res: any[]) => {
      this.languages = res.map(item => ({
        value: item.id,
        name: item.title,
        short: item.short,
        isSelected: item.isSelected,
      }));
      this.languages.forEach(lang => {
        if (lang.isSelected) {
          this.currentLanguage = lang.short;
        }
      });
    });
  }

  changeLanguage(lang) {
    if (lang === 'ar') {
      this.http.post('/app/my/language/2', {}).subscribe(res => {
        location.replace('/ui' + this.route.url);
      });
    }
    if (lang === 'en') {
      this.http.post('/app/my/language/1', {}).subscribe(res => {
        location.replace('/ui' + this.route.url);
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
