import { NbTokenService } from './../token/token.service';
import { SessionTimeoutTimerService } from './../session-timeout-timer.service';
import { NbAuthService } from './../auth.service';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class NbAuthInterceptor implements HttpInterceptor {

    constructor(
        private authService: NbAuthService,
        private router: Router,
        private sessionTimer: SessionTimeoutTimerService,
    ) { }

    /**
     * List all excluded URLs
     */
    protected execpt = [
        '/auth',
        '/static',
    ];

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        const urlExcluded = this.router.url ? 
            this.execpt.reduce((acc, cur) => acc || this.router.url.includes(cur), false) :
            false;
        return next.handle(req).pipe(
            tap(() => this.sessionTimer.reset()),
            catchError(err => {
                // Redirect to auth page and avoid infinite looping
                if (err.status === 401 && !urlExcluded) {
                    this.authService.cleanupBeforeLogout();
                    const startIndex = `${window.location}`.indexOf('/ui/') + 4;
                    const redirectUrl = `${window.location}`.substr(startIndex);
                    this.router.navigate(['/auth/login'], {queryParams: {'redirectURL': encodeURI(redirectUrl)}});
                }
                return throwError(err);
            }),
        );
    }
}
