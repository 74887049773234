import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-notifications',
  styleUrls: ['./notifications.component.scss'],
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  notifications: any;
  unReadCount: number = 0;

  constructor() {
  }

  ngOnInit() {
    this.notifications = [
      {title: 'notification-1', isNew: true},
      {title: 'notification-2', isNew: true},
      {title: 'notification-3', isNew: false},
    ];

    this.notifications.forEach(n => {
      if (n.isNew) {
        this.unReadCount++;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
