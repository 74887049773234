import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public avatar: Subject<any>;

  constructor() {
    this.avatar = new Subject<any>();
  }
}
