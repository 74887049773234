import { Component, OnInit } from '@angular/core';
import { ThemeSettingsService } from '../../../services/theme-settings.service';

@Component({
    selector: 'ngx-logo',
    template: `
        <img *ngIf="logoUrl" [src]="logoUrl" class="header-logo"/>
    `,
})
export class LogoComponent implements OnInit {
    logoUrl?: string;

    constructor (private themeSettingsService: ThemeSettingsService) {}

    ngOnInit(): void {
        this.themeSettingsService.logo$.subscribe((url: string) => {
            this.logoUrl = url;
        });
    }
}
