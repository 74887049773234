import { HttpClient } from '@angular/common/http';
import { repeatWhen, takeUntil, switchMapTo, catchError } from 'rxjs/operators';
import { Subject, timer, Subscription, Observable, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutTimerService {
  private _interval: number = 60000; // polling interval in ms
  private readonly _reset = new Subject<any>();

  timer$: Observable<string>;
  timerSub: Subscription = null;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.timer$ = timer(this._interval, this._interval).pipe(
      takeUntil(this._reset),
      repeatWhen(() => this._reset),
      switchMapTo(this.httpClient.get<string>('/app/session-timeout')),
      catchError((err) => {
        return EMPTY;
      }),
    );
  }

  public run() {
    if (this.timerSub === null) {
      this.timerSub = this.timer$.subscribe((o) => {});
    }
  }

  public reset() {
    // One to unsub
    this._reset.next();

    // One to resub
    this._reset.next();
  }

  public stop() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
      this.timerSub = null;
    }
  }
}
