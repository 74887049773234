import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationService {

  constructor() {
  }

  shouldRedirect() {
    let result = true;
    const url = window.location.href;

    // already https
    if (!url.startsWith('http://')) {
      result = false;
    }

    // run using ng serve, don't redirect
    if (url.includes(':4200/')) {
      result = false;
    }

    return result;
  }

  redirectToHttps() {
    location.assign(location.href.replace('http://', 'https://'));
  }

  // Temporary function
  redirectToOldUI() {
    window.location.href = '/app/index.html';
  }
}
