import { first, filter, map, flatMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface Language {
  id: number;
  title: string;
  short: string;
  isSelected: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private http: HttpClient,
  ) { }

  public getUserSelectedLanguage(): Observable<number> {
    return this.http.get<any>('/app/language').pipe(
      first(),
      flatMap(langList => from(langList)),
      filter((lang: Language) => lang.isSelected),
      map((lang) => lang.id),
    );
  }

  public getUserSelectedLanguageShort(): Observable<string> {
    return this.http.get<any>('/app/language').pipe(
      first(),
      flatMap(langList => from(langList)),
      filter((lang: Language) => lang.isSelected),
      map((lang) => lang.short),
    );
  }
  public changeLanguage(langCode: number): Observable<any> {
    return this.http.post(`/app/my/language/${langCode}`, {});
  }
}
