import { Injectable } from '@angular/core';

// declare const ga: any;

@Injectable()
export class AnalyticsService {

  trackPageViews() {
    // if (this.enabled) {
    //   this.router.events.pipe(
    //     filter((event) => event instanceof NavigationEnd),
    //   )
    //     .subscribe(() => {
    //       ga('send', {hitType: 'pageview', page: this.location.path()});
    //     });
    // }
  }

  trackEvent(eventName: string) {
    // if (this.enabled) {
    //   ga('send', 'event', eventName);
    // }
  }
}
